import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { isBrowser } from '../../../../../shared/Helpers';
import axios from '../../../../../api/axios';
import SolutionLevelTwos from '../../../../../page-components/solution/SolutionLevelTwo';
import PageLayout from '../../../../../shared/PageLayout';
import Hero from '../../../../../page-components/solution/Hero';
import Introduction from '../../../../../page-components/solution/Introduction';

const SolutionLevelTwoPage = ({
  serverData,
  solutionLevelTwo: solutionLevelTwoslug,
  solutionLevelThree: slug,
  solution: solutionSlug,
}) => {
  const { solution: currentSolution, solutions } = serverData;

  if (!currentSolution && isBrowser) {
    navigate('/');
    return null;
  }

  const currentSolutionLevelTwo = currentSolution?.attributes[
    'solution_level_twos'
  ]?.data?.find(
    (solutionLevelTwo) =>
      solutionLevelTwo?.attributes?.slug === solutionLevelTwoslug,
  );

  if (!currentSolutionLevelTwo && isBrowser) {
    navigate('/');
    return null;
  }

  return (
    <PageLayout solutions={solutions}>
      <Hero title={currentSolution?.attributes?.name} />
      <Introduction description={currentSolution?.attributes?.description} />
      <SolutionLevelTwos
        currentSolutionSlug={solutionSlug}
        solutionLevelThreeSlug={slug}
        solutionLevelTwo={currentSolutionLevelTwo}
        solutionLevelTwos={
          currentSolution?.attributes['solution_level_twos']?.data
        }
      />
    </PageLayout>
  );
};

SolutionLevelTwoPage.propTypes = {
  solution: PropTypes.string,
  solutionLevelTwo: PropTypes.string,
  solutionLevelThree: PropTypes.string,
  serverData: PropTypes.object,
};

export async function getServerData({ params }) {
  const currentSolution = params.solution;
  try {
    const { data: solutionsData } = await axios.get(
      '/api/solution-level-ones',
      {
        params: {
          populate: {
            solution_level_twos: {
              populate: {
                solution_level_threes: {
                  populate: {
                    products: { populate: true },
                  },
                },
              },
            },
          },
          filters: {
            slug: { $eq: currentSolution },
          },
        },
      },
    );

    const { data: solutionData } = await axios.get('/api/solution-level-ones', {
      params: {
        fields: ['name', 'slug'],
        populate: {
          solution_level_twos: {
            fields: ['name', 'slug'],
            populate: {
              solution_level_threes: {
                fields: ['name', 'slug'],
                populate: {
                  products: {
                    fields: ['name', 'slug'],
                  },
                },
              },
            },
          },
        },
      },
    });

    return {
      props: {
        solution: solutionsData?.data[0],
        solutions: solutionData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes['solution_level_twos']?.data?.map(
            (subItem) => ({
              title: subItem?.attributes?.name,
              url: subItem?.attributes?.slug,
              subMenu: subItem?.attributes['solution_level_threes']?.data?.map(
                (subSubItem) => ({
                  title: subSubItem?.attributes?.name,
                  url: subSubItem?.attributes?.slug,
                }),
              ),
            }),
          ),
        })),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default SolutionLevelTwoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Solutions", "Layout"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
